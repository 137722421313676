<template>
  <ListContainerWidget
    id="blogs"
    :title="widgetName"
    :app-id="34"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
      <!-- <div ref="target" style="width: 5px; height: 5px; background-color: black;" /> -->

      <!-- Panel actions -->
      <!-- <widget-actions
        item-type="blogs"
        :show-list="true"
        :show-add="canAdd || isStaff"
        :show-filter=false
        :size="items.length"
        @updateSearch="searchItems"
        @openModal="resetInputs"
      /> -->
      <ViewAllButton item-type="blogs" />
    </template>

    <!-- Loading -->
    <b-card-body :class="isMobile?'p-0':''">
      <b-spinner v-if="isLoading" type="grow" small class="mx-auto mt-3 d-block" />

      <!-- Container with data -->
      <div v-else-if="items.length > 0">
        <div ref="container" class="x-scroll-container">
          <!-- Items Loop -->
          <div
            v-for="item in items"
            :key="item.key"
            v-show="canIndex(item)"
            class="x-scroll-element mx-1 mb-3 p-0"
          >
            <b-link :to="{ name: 'blog', params: { id: item.key } }">
              <b-card
                class="card-blog p-0 card-blog-container"
                :img-src="getImageSrc(item.imageURL) ||getImageSrc(item.bannerURL) || Placeholder"
              >
                <b-link :to="{ name: 'blog', params: { id: item.key } }" class="d-flex flex-column justify-content-between h-100">
                  <div>
                    <h4 class="html-text-ellipsis-4 mb-1" v-if="item.title">
                    {{
                      item.title[currentLocale] || Object.values(item.title)[0]
                    }}
                  </h4>
                  <h5 v-if="item.headline" class="html-text-ellipsis-5 mb-1">
                  {{ item.headline[currentLocale] || Object.values(item.headline)[0] }}
                </h5>
              </div>
<!--                   <b-card-text>
                    <p
                      v-if="item.content"
                      class="min-height-3 html-text-ellipsis-2 text-secondary blog-container"
                      v-html="
                        item.content[currentLocale] ||
                        Object.values(item.content)[0]
                      "
                    />
                    <p v-else class="min-height-3" />
                  </b-card-text> -->
                  <b-card-text class="">
                  <div
                    class="w-100 text-muted mb-50">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex ">
                        <span class="mr-1">
                          <feather-icon
                            role="button"
                            size="20"
                            :class="
                              item.likedByMember
                                ? 'profile-likes'
                                : 'profile-icon'
                            "
                            icon="HeartIcon"
                            class="mr-25"
                            @click.stop.prevent="toggleLike(item)"
                            
                          />
                          {{ item.totalLikes || 0 }}
                        </span>
                        <span>
                          <feather-icon
                            role="button"
                            size="20"
                            icon="MessageSquareIcon"
                            class="mr-25"
                          />
                          {{ item.commentCount || 0 }}
                        </span>
                      </div>
                      <div>
                      <small v-if="item.date" class="text-muted">
                      {{ timestampToTime(item.date) }}</small
                    >
                    </div>
                    </div>
                  </div>
                  </b-card-text>
                </b-link>
              </b-card>
            </b-link>
          </div>

          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="blogPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>

  </ListContainerWidget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import BlogPlaceholder from "@/assets/images/placeholders/light/blog.svg";
import { getImageResource } from "@/@core/utils/image-utils";
// import FileTypes from "@/@core/constants/FileTypes";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
// import { quillEditor } from "vue-quill-editor";
import moment from "moment";
import Placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
// import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import vSelect from "vue-select";
// import FileUpload from "@core/components/files/FileUpload.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

export default {
  name: "BlogListWidget",

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  components: {
    ListContainerWidget,
    // WidgetActions: () => import ("@/@core/components/widget-actions/WidgetActions.vue"  /* webpackChunkName: "WidgetActions" */),
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    // quillEditor,
    // vSelect,
    // FileUpload: () => import ("@core/components/files/FileUpload.vue"  /* webpackChunkName: "FileUpload" */),
  },

  mixins: [WidgetLayoutMixin,ToastNotificationsMixin],

  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      isSending: false,
      lastLoadedPage: 1,
      loadingNext: false,
      formInput: {},
      imageURL: null,
      imageSrc: null,
      bannerURL: null,
      bannerSrc: null,
      searchInput: "",
      results: [],
      Placeholder,
      date: null,
    };
  },
  computed: {
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    // canAdd() {
    //   return checkPermissions(
    //     "create",
    //     "blog",
    //     this.loggedMemberRoles,
    //     this.collective
    //   );
    // },
    // FileTypes() {
    //   return FileTypes;
    // },
    // Load data from store
    itemsData() {
      return this.$store.getters.blogs;
    },
    items() {
      // return this.itemsData.unpaginated;
      if (this.itemsData && this.itemsData.unpaginated && this.itemsData.unpaginated.length) {
        return this.searchInput ? this.results : this.itemsData.unpaginated;
      }
      return [];
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    blogPlaceholder() {
      return BlogPlaceholder;
    },
    isMobile() {
      return window.innerWidth < 576;
    },
  },
  watch: {
    // targetIsVisible: {
    //   // immediate: true,
    //   handler (newValue) {
    //     console.log("++++++++++++ --> WATCH!!! BlogListWidget.vue (targetIsVisible): ", newValue)
    //     // if (newValue && !this.itemsData.length) {
    //     //   console.log("2 --> WATCH!!! BlogListWidget.vue (targetIsVisible): ", newValue)
    //     //   // this.loadAllData()
    //     // }
    //   }
    // },

    imageURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },

  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    canIndex(item) {
      if (this.loggedUser && item.poweredBy &&
        this.loggedUser.key === item.poweredBy.key ||
        checkPermissions(
          "index",
          "blog",
          this.loggedMemberRoles,
          this.collective
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    resetInputs() {
      this.formInput = {};
      this.imageURL = null;
      this.bannerURL = null;
      this.imageSrc = null;
      this.bannerSrc = null;
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format("DD-MM-YYYY");
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    async fetchData(force = false) {
      await this.$store.dispatch("getItems", {
        itemType: "blogs",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 16,
          orderByDate: -1,
          reduced: 1
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        // this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    async handleCreateItem() {
      if (!this.formInput.name) {
        return;
      }
        const file = {};
        if (this.imageURL) file.imageURL = this.imageURL;
        if (this.bannerURL) file.bannerURL = this.bannerURL;
      try {
        this.isSending = true;
        const requestConfig = { title: {}, content: {}, headline: {} };
        const locale = this.$store.state.locale.currentLocale;
        requestConfig.title[locale] = this.formInput.name;
        requestConfig.content[locale] = this.formInput.description;
        if (this.date !== null) {
          requestConfig.date = new Date(this.date);
        }
        requestConfig.headline[locale] = this.formInput.headline;
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "blogs",
            requestConfig,
          },
          file,
          noSet: true,
        });
        this.fetchData(true)
        this.formInput = {};
        this.imageURL = null;
        this.bannerURL = null;
        this.isSending = false;
        this.notifySuccess(this.$t('success-message.general-success-create'));

      } catch {
        this.notifyError(this.$t('error-message.general-error'));
        this.isSending = false;
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) =>
        Object.values(item.title)[0].toLowerCase().includes(searchTerm)
      );
    },
    async toggleLike(item) {
      await this.$store.dispatch("toggleLike", {
        itemType: "blogs",
        morphType: "blogs",
        key: item?.key,
      });
    },
  },
};
</script>

<style>
.card-blog-container {
  height: 375px;
}
.date-container {
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  width: 100%;
}
.blog-container img {
  width: 100%;
}
.card-img {
  height: 200px !important;
}
.icon-div {
  position: absolute;
  bottom: 0;
  background-color: white;
  margin: auto;
  padding: 2px;
  padding-bottom: 6px;
}
</style>
